import axios from '@axios'

export default {
  namespaced: true,
  state: {
    supervisores: []
  },
  getters: {
    supervisores: state => {
      return state.supervisores
    }
  },
  mutations: {
    supervisores(ctx, supervisores) {
      ctx.supervisores = supervisores
    }
  },
  actions: {
    fetchSupervisores(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('https://reports.renovaremcristo.org.br/renovar-reports/api/executor', {
          "key": "INFORMACOES_DETALHADAS_SUPERVISORES",
          "params": params
        })
        .then(function(response) {
          ctx.commit('supervisores', response.data.data)
          return resolve(response)
        })
        .catch(error => reject(error))
      })
    }
  },
}
