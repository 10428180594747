<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="mt-2 ml-2">
      <b-row>
        <b-col cols="12">
          <h2>Supervisores</h2>
        </b-col>
      </b-row>
    </div>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="8">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Filtrar..."
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select mr-1"
              placeholder="Rede"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
            <vue-json-to-csv 
              :json-data="teste"
              :labels="{
                leaders: { title: 'Lider' },
                description: { title: 'Descrição'},
                network_name: { title: 'Rede' },
                pgs_count: { title: 'Numero de PGs'},
                multiplicated_pgs_count: { title: 'PGs multiplicados'}
              }"
              csv-title="Supervisores"
              separator=";"
              >
              <b-button variant="primary">
                Download
              </b-button>
            </vue-json-to-csv>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      :items="filterSupervisor(teste)"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      stacked="sm"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #head(invoiceStatus)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span class="text-muted"
            >Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de
            {{ dataMeta.of }} supervisores</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { ref, onUnmounted, computed } from "@vue/composition-api";
import store from "@/store";
import VueJsonToCsv from 'vue-json-to-csv';

import invoiceStoreModule from "./supervisorsStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    VueJsonToCsv,
    vSelect,
  },
  setup() {
    try {
      const INVOICE_APP_STORE_MODULE_NAME = "appsupervisores";

      // Register module
      if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
          store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
      });

      const statusOptions = [
        "Rede de Homens",
        "Rede de Mulheres",
        "Rede de Casais",
        "Rede de Jovens",
      ];
      const fetchSupervisores = () => {
        store.dispatch("appsupervisores/fetchSupervisores");
      };

      fetchSupervisores();

      const teste = computed(() => store.state.appsupervisores.supervisores);
      const totalInvoices = ref(0);

      const dataMeta = computed(() => {
        return {
          from: perPage.value * currentPage.value + 1 - perPage.value,
          to:
            perPage.value * currentPage.value > totalInvoices.value
              ? totalInvoices.value
              : perPage.value * currentPage.value,
          of: totalInvoices.value,
        };
      });

      const tableColumns = [
        { key: "id", label: "#", sortable: true },
        { key: "leaders", label: "Lider", sortable: true },
        { key: "description", label: "Descrição", sortable: true },
        { key: "network_name", label: "Rede", sortable: true },
        { key: "pgs_count", label: "Numero de PGs", sortable: true },
        {
          key: "multiplicated_pgs_count",
          label: "PGs multiplicados",
          sortable: true,
        },
      ];
      const perPage = ref(10);
      const currentPage = ref(1);
      const perPageOptions = [10, 25, 50, 100];
      const searchQuery = ref("");
      const sortBy = ref("id");
      const isSortDirDesc = ref(true);
      const statusFilter = ref(null);

      function filterSupervisor(supervisores) {
        if (statusFilter.value != null) {
          supervisores = supervisores.filter((word) =>
            word.network_name.includes(statusFilter.value)
          );
        }

        supervisores = supervisores.filter(
          (word) =>
            word.leaders
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase()) ||
            word.description
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase())
        );

        totalInvoices.value = supervisores.length ?? 0;

        return supervisores;
      }

      return {
        tableColumns,
        perPage,
        currentPage,
        totalInvoices,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,

        statusFilter,
        statusOptions,

        avatarText,
        teste,
        fetchSupervisores,
        filterSupervisor,
      };
    } catch (ex) {
      console.log(ex);
    }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
